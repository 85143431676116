!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '242772329749036');
    fbq('track', 'PageView');
if (
  window.location.href.indexOf('global-multi-asset') !== -1 &&
  window.location.href.indexOf('global-multi-asset/construction') === -1
) {
  const pricingSlider = document.getElementById('pricing_slider');
  const riskSlider = document.getElementById('risk_slider');
  const accountBalance = document.getElementById('accountBalance');
  const swFees = document.getElementById('swFees');
  const riskCategory = document.getElementById('risk_category');
  const pricingProgressBar = document.querySelector('.pricing__progress-bar');
  const riskProgressBar = document.querySelector('.overview__progress-bar');
  const initialBalance = 5000;
  const initialFee = '1.5%';
  let riskScore = 5;
  let riskScores = null;
  let lastRiskScore = 4;
  let lastAccountBalance = 500000;

  accountBalance.innerHTML = `$ ${formatNumber(initialBalance)}`;

  swFees.innerHTML = initialFee;

  pricingSlider.oninput = function () {
    const newValue = parseInt(this.value);
    pricingProgressBar.style.width = `${newValue / 5000}%`;
    accountBalance.innerHTML = `$${formatNumber(newValue)}`;
    calculateFees();
  };

  const calculateFees = () => {
    const balance = pricingSlider.value;
    let fee = '1.5%';
    if (balance >= 5000 && balance < 100000) {
      fee = '1.5%';
    }
    if (balance >= 100000 && balance <= 500000) {
      fee = '1.25%';
    }
    swFees.innerHTML = fee;
  };

  // pricingSlider.addEventListener('change', () => {
  //   calculateFees();
  // });
  riskSlider.oninput = function () {
    switch (this.value) {
      case '1':
        riskProgressBar.style.width = `5%`;
        break;
      case '2':
        riskProgressBar.style.width = `15%`;
        break;
      case '3':
        riskProgressBar.style.width = `25%`;
        break;
      case '4':
        riskProgressBar.style.width = `35%`;
        break;
      case '5':
        riskProgressBar.style.width = `45%`;
        break;
      case '6':
        riskProgressBar.style.width = `55%`;
        break;
      case '7':
        riskProgressBar.style.width = `65%`;
        break;
      case '8':
        riskProgressBar.style.width = `75%`;
        break;
      case '9':
        riskProgressBar.style.width = `85%`;
        break;
      case '10':
        riskProgressBar.style.width = `100%`;
        break;
    }
  };

  riskSlider.addEventListener('change', () => {
    riskScore = riskSlider.value;
    loadChart(riskScore, riskScores);
  });

  getRiskScores();

  async function getRiskScores() {
    try {
      axios.defaults.baseURL = 'https://api.nbkcapitalsmartwealth.com/api/v2';
      const headerConfig = {
        'X-Platform': 'web',
        'X-Version': '1.0',
        'X-Locale': 'en_US',
        ContentType: 'application/json',
      };
      axios.defaults.headers.common = { ...headerConfig };
      const {
        data: {
          meta: { risk_scores },
        },
      } = await axios.get(`general/variables`);
      riskScores = risk_scores;
      loadChart(riskScore, riskScores);
    } catch (error) {
      console.log(error);
    }
  }

  function loadChart(riskScore, riskScores) {
    const data = sortColumnChartData(riskScores[riskScore - 1].groups);
    if (!data) {
      return;
    }
    riskCategory.innerHTML = riskScores[riskScore - 1].risk.category;
    let chart = am4core.create('bar_chart', am4charts.XYChart);
    chart.rtl = window.location.href.indexOf('/ar/') !== -1;
    chart.hiddenState.properties.opacity = 0;
    chart.data = data || [];
    chart.colors.list = [
      am4core.color('#77e6b6'),
      am4core.color('#14a3eb'),
      am4core.color('#00d5d6'),
      am4core.color('#00da92'),
      am4core.color('#dde000'),
      // am4core.color('#e59800'),
    ];
    chart.logo.disabled = true;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.dataFields.category = 'name';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.fontSize = 10;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 100;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = 'name';
    series.dataFields.valueY = 'value';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.width = 60;

    series.columns.template.adapter.add('fill', function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{value}%';
    valueLabel.label.fontSize = 12;
    valueLabel.label.contentWidth = 20;
    valueLabel.label.verticalCenter = 'bottom';
  }
}

function sortColumnChartData(data) {
  const array = [];
  data.push({
    description: 'Cash',
    percentage: 0.02,
  });
  data.forEach(({ description, percentage }) => {
    array.push({
      name: description,
      value: (percentage * 100).toFixed(1),
    });
  });
  return array;
}

//pze2qq1c
//sn24q6sl

var appId = 'sn24q6sl';
var currentAccess = 'other';

var loadIntercom = function () {
  window.intercomSettings = {
    app_id: appId,
  };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;

      function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + appId;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
        return s;
      }
      l();
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

const formLauncher = document.getElementById('launch');
if (formLauncher) {
  formLauncher.addEventListener('click', () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  });
}

loadIntercom();

if (
  window.location.href.indexOf('watani-kd-mmf') !== -1 ||
  window.location.href.indexOf('watani-usd-mmf') !== -1 ||
  window.location.href.indexOf('watani-islamic-kd-mmf') !== -1 ||
  window.location.href.indexOf('watani-islamic-usd-mmf') !== -1 ||
  window.location.href.indexOf('nbk-equity') !== -1 ||
  window.location.href.indexOf('gulf-equity') !== -1 ||
  window.location.href.indexOf('regional-bond-and-sukuk-fund') !== -1
) {
  const fundId = getFundID();
  const fundTitle = document.querySelector('.hero__title');
  const fundDescription = document.querySelector('.info__text');
  const fundManager = document.querySelector('.info-box__value--fm');
  const fundStructure = document.querySelector('.info-box__value--fs');
  const liquidity = document.querySelector('.info-box__value--liq');
  const inception = document.querySelector('.info-box__value--incep');
  const latestNav = document.querySelector('.info-box__value--lnav');
  const minInvestment = document.querySelector('.info-box__value--mi');
  const depositIncrement = document.querySelector('.info-box__value--di');
  const date = document.querySelector('.info-box__value--date');
  const aoaLink = document.querySelector('.action--aoa');
  const fundFactsButton = document.querySelector('.action--facts');
  const managementTeamButton = document.querySelector('.action--team');
  const factsModal = document.querySelector('.modal--facts');
  const teamModal = document.querySelector('.modal--team');
  const tableHeader = document.querySelector('.table-header');
  const tableRowFund = document.querySelector('.table-row--fund');
  const fundNameCell = document.querySelector('.table-cell--fund-name');
  const tableNote = document.querySelector('.performance-table__note');
  const locale = window.location.pathname.indexOf('/ar/') !== -1 ? 'ar' : 'en';
  const headerConfig = {
    'X-Platform': 'web',
    'X-Version': '1.0',
    'X-Locale': locale === 'ar' ? 'ar_AE' : 'en_US',
    ContentType: 'application/json',
  };
  const currencies = {
    en_US: {
      USD: {
        code: 'USD',
        symbol: '$',
      },
      KWD: {
        code: 'KWD',
        symbol: 'KD',
      },
    },
    ar_AE: {
      USD: {
        code: 'USD',
        symbol: '$',
      },
      KWD: {
        code: 'KWD',
        symbol: 'د.ك.',
      },
    },
  };
  let currency = '';
  let fundFacts = [];
  let managementTeam = [];
  let fund;

  axios.defaults.baseURL = 'https://api.nbkcapitalsmartwealth.com/api/v2';
  document.onload = getNavPerformance(fundId);

  function getFundID() {
    const pathname = window.location.pathname;
    if (pathname.includes('watani-kd-mmf')) {
      return '2628';
    }
    if (pathname.includes('watani-usd-mmf')) {
      return '2630';
    }
    if (pathname.includes('watani-islamic-kd-mmf')) {
      return '2632';
    }
    if (pathname.includes('watani-islamic-usd-mmf')) {
      return '2633';
    }
    if (pathname.includes('nbk-equity')) {
      return '2550';
    }
    if (pathname.includes('gulf-equity')) {
      return '2647';
    }
    if (pathname.includes('regional-bond-and-sukuk-fund')) {
      return '2657';
    }
  }

  function formatAmount(currency, amount) {
    if (locale === 'ar' && currency === currencies.ar_AE.KWD.symbol) {
      return `${amount}${currency}`;
    } else {
      return `${currency}${amount}`;
    }
  }

  getProducts();

  async function getProducts() {
    try {
      axios.defaults.headers.common = { ...headerConfig };
      const {
        data: {
          meta: { bank_funds_products: bankFundsProducts },
        },
      } = await axios.get('/general/bank-funds/categories');
      fund = bankFundsProducts[fundId];
      fundTitle.innerHTML = fund.official_name;
      fundDescription.innerHTML = fund.description;
      fundManager.innerHTML = fund.fund_manager;
      fundStructure.innerHTML = fund.fund_structure;
      liquidity.innerHTML = fund.liquidity;
      inception.innerHTML = fund.inception_date;
      latestNav.innerHTML = formatAmount(
        fund.currency_symbol,
        fund.latest_nav.toFixed(2)
      );
      depositIncrement.innerHTML = formatAmount(
        fund.currency_symbol,
        addCommaToNumber(fund.transfers_rules.transfer_increment)
      );
      minInvestment.innerHTML = fund.minimum_initial_investment.replace(
        ' ',
        ''
      );
      date.innerHTML = fund.valuation_day;
      fundFacts = fund.fund_facts;
      managementTeam = fund.management_team;
      fundNameCell.innerHTML = fund.long_name;
      currency = fund.currency_symbol;
      fundFacts.map((fundFact) => {
        const row = document.createElement('div');
        const name = document.createElement('div');
        const value = document.createElement('div');
        row.classList.add('modal__row', 'flex', 'flex-align-center', 'flex-sb');
        name.classList.add('modal__name');
        name.innerHTML = fundFact.type;
        value.classList.add('modal__value');
        value.innerHTML = fundFact.value;
        row.appendChild(name);
        row.appendChild(value);
        factsModal.querySelector('.modal__body').appendChild(row);
      });
      managementTeam.map((entry) => {
        const row = document.createElement('div');
        const name = document.createElement('div');
        const value = document.createElement('div');
        row.classList.add('modal__row', 'flex', 'flex-align-center', 'flex-sb');
        name.classList.add('modal__name');
        name.innerHTML = entry.type;
        value.classList.add('modal__value');
        value.innerHTML = entry.value;
        row.appendChild(name);
        row.appendChild(value);
        teamModal.querySelector('.modal__body').appendChild(row);
      });
    } catch (error) {
      console.log(error);
    }
  }

  aoaLink.addEventListener('click', () => {
    window.location.href = fund.article_association.url;
  });

  fundFactsButton.addEventListener('click', () => {
    factsModal.style.display = 'block';
  });

  managementTeamButton.addEventListener('click', () => {
    teamModal.style.display = 'block';
  });

  global.closeModal = (type) => {
    switch (type) {
      case 'facts':
        factsModal.style.display = 'none';
        break;
      case 'team':
        teamModal.style.display = 'none';
        break;
      default:
        break;
    }
  };

  const NAVChart = (data) => {
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;

    if (data.length === 0) {
      return;
    }

    // Create chart instance
    const chart = am4core.create('nav-chart', am4charts.XYChart);
    chart.paddingRight = 0;
    chart.paddingLeft = 0;
    chart.dateFormatter.dateFormat = 'DD/MM/YYYY';
    chart.logo.disabled = true;

    // Add data
    chart.data = data;
    chart.numberFormatter.numberFormat =
      locale === 'ar' && currency === currencies.ar_AE.KWD.symbol
        ? `#0.##a${currency}`
        : `${currency}#0.##a`;
    chart.colors.list = [am4core.color('#1439da')];

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.tooltip.disabled = true;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.line.strokeOpacity = 1;
    dateAxis.renderer.line.strokeWidth = 0;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.fontSize = 14;
    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.labels.template.fill = am4core.color('#494949');

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 0;
    valueAxis.renderer.labels.template.fill = am4core.color('#051366');
    valueAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.fontSize = 14;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'ay';
    series.dataFields.dateX = 'date';
    series.strokeWidth = 2;
    series.tensionX = 0.8;
    series.fillOpacity = 0.1;
    series.background.fill = am4core.color('#ff0000');
    series.tooltip.background.fill = am4core.color('#262935');
    series.tooltip.background.cornerRadius = 4;
    series.tooltip.background.strokeWidth = 0;
    series.tooltip.getFillFromObject = false;
    series.tooltip.getStrokeFromObject = false;
    series.tooltip.width = 133;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    const bullethover = bullet.states.create('hover');
    bullet.properties.scale = 0;
    bullethover.properties.scale = 1.3;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
  };

  function customizeHistoricalData(historicalData) {
    const data = [];
    let date;
    for (let i = 0; i < historicalData.length; i += 1) {
      date = moment(historicalData[i].date).format('YYYY-MM-DD');
      data.push({
        date,
        ay: historicalData[i].value,
      });
    }
    return data;
  }

  async function getNavPerformance(fundId) {
    try {
      const currentDate = moment().format('YYYY-MM-DD');
      const {
        data: {
          meta: { historical },
        },
      } = await axios.get(
        `/general/mmf/nav?start_date=2010-09-14&end_date=${currentDate}&investment_product=${fundId}`
      );
      const {
        data: {
          meta: { available_keys, fund, benchmark, note },
        },
      } = await axios.get(
        `/general/bank-funds/performance?investment_product=${fundId}`
      );
      const index = note.label.indexOf('.');
      let finalNote = '';
      let note1 = note.label.substr(0, index);
      let note2 = note.label.substr(index + 2);
      if (note.superscripts) {
        const superElement1 = document.createElement('sup');
        const superElement2 = document.createElement('sup');
        note1 = note1.replace(note.superscripts[0].key, '');
        note2 = note2.replace(note.superscripts[1].key, '');
        const parenthesesOneIndex = note1.indexOf('(');
        const parenthesesTwoIndex = note2.indexOf('(');
        const note1First = note1.substr(0, parenthesesOneIndex + 1);
        const note1Second = note1.substr(parenthesesOneIndex + 1);
        const note2First = note2.substr(0, parenthesesTwoIndex + 1);
        const note2Second = note2.substr(parenthesesTwoIndex + 1);
        superElement1.innerHTML = note.superscripts[0].value;
        superElement2.innerHTML = note.superscripts[1].value;
        finalNote = `${note1First}${superElement1.outerHTML}${note1Second}. ${note2First}${superElement2.outerHTML}${note2Second}`;
      } else {
        finalNote = note.label;
      }
      tableNote.innerHTML = finalNote;

      fund.map((entry) => {
        const headerCell = document.createElement('div');
        const superElement = document.createElement('sup');
        superElement.innerHTML = entry.superscript
          ? entry.superscript.value
          : '';
        headerCell.classList.add('table-cell');
        headerCell.innerHTML = entry.superscript
          ? entry.label.replace(entry.superscript.key, '')
          : entry.label;
        headerCell.appendChild(superElement);
        const fundCell = document.createElement('div');
        fundCell.classList.add('table-cell');
        fundCell.innerHTML = entry.value ? `${entry.value.toFixed(2)}%` : 'N/A';
        tableHeader.appendChild(headerCell);
        tableRowFund.appendChild(fundCell);
      });

      if (hasValues(benchmark)) {
        const tableRow = document.createElement('div');
        const mainCell = document.createElement('div');
        tableRow.classList.add('table-row', 'table-row--benchmark');
        mainCell.classList.add('table-cell', 'cell-performance');
        tableRow.appendChild(mainCell);
        benchmark.map((entry) => {
          const benchmarkCell = document.createElement('div');
          benchmarkCell.classList.add('table-cell');
          benchmarkCell.innerHTML = `${
            entry.value ? entry.value.toFixed(2) : ''
          }%`;
          tableRow.appendChild(benchmarkCell);
        });
      }
      NAVChart(customizeHistoricalData(historical));
    } catch (error) {
      console.log(error);
    }
  }

  const hasValues = (data) => {
    return data.find((entry) => entry.value);
  };

  const addCommaToNumber = (value) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  async function getTableData() {}
}

import Glide from '@glidejs/glide';

const navItems = [].slice.call(document.querySelectorAll('.nav-bar__item'));
const questions = [...document.querySelectorAll('.question__head')];
const menuIcon = document.querySelector('.menu-icon');
const mobileMenu = document.querySelector('.mobile-menu');
const investingLink = document.querySelector('.mobile-links__link--investing');
const investingSubMenu = document.querySelector('.mobile-sub-menu--investing');
const planningLink = document.querySelector('.mobile-links__link--planning');
const planningSubMenu = document.querySelector('.mobile-sub-menu--planning');
const learningLink = document.querySelector('.mobile-links__link--learning');
const learningSubMenu = document.querySelector('.mobile-sub-menu--learning');
const assetsLink = document.querySelector('.mobile-sub-menu__link--assets');
const assetsSubMenu = document.querySelector('.mobile-sub-menu--assets');
const mobileMenuClose = document.querySelector('.mobile-menu__close');
const footerDate = document.querySelector('.footer__date');
const locale = window.location.pathname.indexOf('/ar/') !== -1 ? 'ar' : 'en';
const getStartedLinks = document.querySelectorAll('.get-started-link');
const loginLinks = document.querySelectorAll('.login-link');
const blackGetStartedLinks = document.querySelectorAll(
  '.get-started-link-black'
);

const glideConfig = {
  type: 'carousel',
  gap: '15px',
  direction: isArabic() ? 'rtl' : 'ltr',
  breakpoints: {
    991: {
      perView: 2,
    },
    768: {
      perView: 1,
    },
  },
};

footerDate.innerHTML = new Date().getFullYear();

let URL = '';

getStartedLinks.forEach((getStartedLink) => {
  getStartedLink.href = `##web_app_url##start?lang=${locale}`;
});

loginLinks.forEach((loginLink) => {
  loginLink.href = `##web_app_url##login?lang=${locale}`;
});

blackGetStartedLinks.forEach((getStartedLink) => {
  getStartedLink.href = `##web_app_url##start?lang=${locale}&isBlackInterested=true`;
});

menuIcon.addEventListener('click', () => {
  mobileMenu.classList.add('mobile-menu--active');
});
mobileMenuClose.addEventListener('click', () => {
  mobileMenu.classList.remove('mobile-menu--active');
});

if (document.body.id !== 'sw-black') {
  investingLink.addEventListener('click', () => {
    if (!investingSubMenu.classList.contains('mobile-sub-menu--active')) {
      // planningSubMenu.classList.remove('mobile-sub-menu--active');
      learningSubMenu.classList.remove('mobile-sub-menu--active');
      investingSubMenu.classList.add('mobile-sub-menu--active');
    } else {
      investingSubMenu.classList.remove('mobile-sub-menu--active');
    }
  });
  // planningLink.addEventListener('click', () => {
  //   if (!planningSubMenu.classList.contains('mobile-sub-menu--active')) {
  //     investingSubMenu.classList.remove('mobile-sub-menu--active');
  //     learningSubMenu.classList.remove('mobile-sub-menu--active');
  //     planningSubMenu.classList.add('mobile-sub-menu--active');
  //   } else {
  //     planningSubMenu.classList.remove('mobile-sub-menu--active');
  //   }
  // });
  learningLink.addEventListener('click', () => {
    if (!learningSubMenu.classList.contains('mobile-sub-menu--active')) {
      // planningSubMenu.classList.remove('mobile-sub-menu--active');
      investingSubMenu.classList.remove('mobile-sub-menu--active');
      learningSubMenu.classList.add('mobile-sub-menu--active');
    } else {
      learningSubMenu.classList.remove('mobile-sub-menu--active');
    }
  });
  assetsLink.addEventListener('click', () => {
    if (!assetsSubMenu.classList.contains('mobile-sub-menu--active')) {
      assetsSubMenu.classList.add('mobile-sub-menu--active');
    } else {
      assetsSubMenu.classList.remove('mobile-sub-menu--active');
    }
  });
}

window.addEventListener('resize', closeMobileMenu);

function closeMobileMenu() {
  const screenWidth = window.outerWidth;
  if (screenWidth > 991) {
    mobileMenu.classList.remove('mobile-menu--active');
  }
}
// =========== Mobile Menu Functionality End ==============

// =========== Language Buttons Functionality Start ==============
const languageLinks = document.querySelectorAll('.language-link');

languageLinks.forEach((languageLink) => {
  languageLink.addEventListener('click', changeLanguage);
});

const setLocale = function () {
  if (locale === 'en') {
    updateLanguageLiks('ar');
  }
  if (locale === 'ar') {
    updateLanguageLiks('en');
  }
};

function changeLanguage() {
  const origin = window.location.origin;
  let path = window.location.pathname;
  let newLocale = 'en';
  if (!locale || locale === 'en') {
    newLocale = 'ar';
    path = `/${newLocale}${path}`;
  } else {
    path = path.replace('/ar/', '/');
  }
  updateLanguageLiks(newLocale);
  window.location.href = `${origin + path}`;
}

function updateLanguageLiks(locale) {
  if (locale === 'ar') {
    languageLinks.forEach((languageLink) => {
      languageLink.textContent = 'عربي';
    });
    return;
  }
  languageLinks.forEach((languageLink) => {
    languageLink.textContent = 'English';
  });
}
// =========== Language Buttons Functionality End ==============

setLocale();

handleRedirectionLinks();
if (locale === 'ar' && window.location.pathname.indexOf('ar') === -1) {
  URL = `/${locale}${window.location.pathname}`;
  window.location.href = URL;
}

navItems.forEach((navItem) => {
  const subMenu = navItem.querySelector('.sub-menu');
  navItem.addEventListener('click', () => {
    const nonSelectedLinks = [...navItems].filter(
      ({ dataset }) => dataset.name != navItem.dataset.name
    );
    nonSelectedLinks.forEach((otherItem) => {
      const otherSubMenu = otherItem.querySelector('.sub-menu');
      otherItem.classList.remove('nav-bar__item--active');
      otherSubMenu ? otherSubMenu.classList.remove('sub-menu--active') : null;
    });
    navItem.classList.toggle('nav-bar__item--active');
    subMenu.classList.toggle('sub-menu--active');
  });
});

if (document.body.id === 'landing') {
  mountGlide('glide--overview');
}

if (document.body.id === 'funds') {
  mountGlide('glide--mmf');
  mountGlide('glide--regional');
}
function handleRedirectionLinks() {
  const screenWidth = window.innerWidth;

  const appLinks = document.querySelectorAll('.app-link');
  if (screenWidth <= 768) {
    appLinks.forEach((appLink) => {
      appLink.href = 'https://nbkcsw.onelink.me/xILK/install';
    });
  }
}

function mountGlide(className) {
  const glideInstance = new Glide(`.${className}`, glideConfig);
  glideInstance.mount();
}

questions.map((question) => {
  question.addEventListener('click', () => {
    question
      .querySelector('.question__action-icon')
      .classList.toggle('question__action-icon--active');
    const answer = question.nextElementSibling;
    if (answer.style.maxHeight) {
      answer.style.marginBottom = '0';
      answer.style.maxHeight = null;
    } else {
      answer.style.maxHeight = answer.scrollHeight + 'px';
      answer.style.marginBottom = '3.2rem';
    }
  });
});

function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function validateEmail(email) {
  const validEmailAddress =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return isArabic() ? 'البريد الالكتروني مطلوب.' : 'Email is required.';
  }
  if (!validEmailAddress.test(String(email).toLowerCase())) {
    return isArabic()
      ? 'يُرجى إدخال بريد إلكتروني صحيح'
      : 'Please enter a valid email address.';
  }
  return '';
}
function isArabic() {
  return window.location.pathname.indexOf('/ar/') !== -1;
}

global.subscribeToNewsLetter = async () => {
  try {
    const emailInput = document.getElementById('email');
    const icon = document.querySelector('.form__input-icon');
    const email = emailInput.value;
    const errorMessage = document.querySelector('.form__error');
    errorMessage.textContent = '';
    if (validateEmail(email).length > 0) {
      emailInput.classList.add('form__input--error');
      emailInput.classList.remove('form__input--success');
      icon.classList.add('form__input-icon--fail');
      errorMessage.classList.remove('form__error--success');
      return (errorMessage.textContent = validateEmail(email));
    }
    const data = {
      email,
    };
    axios.defaults.baseURL = 'https://api.nbkcapitalsmartwealth.com/api/v2';
    const headerConfig = {
      'X-Platform': 'web',
      'X-Version': '1.0',
      'X-Locale': 'en_US',
      ContentType: 'application/json',
    };
    axios.defaults.headers.common = { ...headerConfig };
    await axios.post('/general/newsletter', data);
    emailInput.classList.add('form__input--success');
    errorMessage.classList.add('form__error--success');
    errorMessage.textContent = isArabic()
      ? 'أنت الآن مسجل في نشرتنا الإخبارية.'
      : 'You are now registered to our newsletter.';
    icon.classList.add('form__input-icon--success');
    icon.classList.remove('form__input-icon--fail');
    // window.location.href = `##web_app_url##start?lang=${locale}`; // NEOS-1868
  } catch (error) {
    console.log(error);
    const emailInput = document.getElementById('email');
    const icon = document.querySelector('.form__input-icon');
    const errorMessage = document.querySelector('.form__error');
    if (error.response.status === 409) {
      icon.classList.add('form__input-icon--fail');
      icon.classList.remove('form__input-icon--success');
      emailInput.classList.add('form__input--error');
      emailInput.classList.remove('form__input--success');
      errorMessage.classList.remove('form__error--success');
      errorMessage.classList.add('form__error--error');
      errorMessage.textContent = isArabic()
        ? 'البريد الالكتروني موجود بالفعل.'
        : 'Email already exists.';
    }
  }
};

const consBtn = document.getElementById('cons_btn');
const blncdBtn = document.getElementById('blncd_btn');
const grwBtn = document.getElementById('grw_btn');
const viewCompaniesBtn = document.querySelector('.exposure__view');
const companiesLogos = document.querySelector('.exposure__icons');
global.getHistoricalPerformance = async function (riskScore) {
  try {
    axios.defaults.baseURL = 'https://api.nbkcapitalsmartwealth.com/api/v2';
    const headerConfig = {
      'X-Platform': 'web',
      'X-Version': '1.0',
      'X-Locale': 'en_US',
      ContentType: 'application/json',
    };
    axios.defaults.headers.common = { ...headerConfig };
    const {
      data: {
        meta: { historical_data, interest_rate_percentage },
      },
    } = await axios.get(`/general/history/performance/${riskScore}`);
    if (riskScore === 2) {
      consBtn.classList.add('risks__btn--active');
      blncdBtn.classList.remove('risks__btn--active');
      grwBtn.classList.remove('risks__btn--active');
    }
    if (riskScore === 5) {
      consBtn.classList.remove('risks__btn--active');
      blncdBtn.classList.add('risks__btn--active');
      grwBtn.classList.remove('risks__btn--active');
    }
    if (riskScore === 9) {
      consBtn.classList.remove('risks__btn--active');
      blncdBtn.classList.remove('risks__btn--active');
      grwBtn.classList.add('risks__btn--active');
    }
    document.getElementById('rate').innerHTML = interest_rate_percentage;
    historicalChart(customizeHistoricalData(historical_data));
  } catch (error) {
    console.log(error);
  }
};

if (document.body.id === 'multi-asset-construction') {
  document.onload = getHistoricalPerformance(2);
  viewCompaniesBtn.addEventListener('click', () => {
    const locale =
      window.location.pathname.indexOf('/ar/') !== -1 ? 'ar' : 'en';
    if (!companiesLogos.classList.contains('exposure__icons--active')) {
      const btnText = locale === 'ar' ? 'عرض أقل' : 'View less';
      companiesLogos.classList.add('exposure__icons--active');
      viewCompaniesBtn.textContent = btnText;
    } else {
      const btnText = locale === 'ar' ? 'عرض الكل' : 'View all';
      companiesLogos.classList.remove('exposure__icons--active');
      viewCompaniesBtn.textContent = btnText;
    }
  });
}

function customizeHistoricalData(historicalData) {
  const data = [];
  let date;
  for (let i = 0; i < historicalData.length; i += 1) {
    date = moment(parseInt(historicalData[i].date_js, 10) * 1000).format(
      'YYYY-MM-DD'
    );
    data.push({
      date,
      ay: historicalData[i].smartWealthValue,
    });
  }
  return data;
}

function historicalChart(data) {
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const chart = am4core.create('historical_chart', am4charts.XYChart);
  chart.data = data || [];
  chart.logo.disabled = true;
  chart.dateFormatter.inputDateFormat = 'dd-MM-yyyy';
  chart.colors.list = [am4core.color('#77e6b6')];
  am4core.useTheme(am4themes_animated);

  const xAxix = chart.xAxes.push(new am4charts.DateAxis());
  xAxix.tooltip.disabled = false;
  xAxix.tooltipDateFormat = 'dd-MM-yyyy';
  xAxix.renderer.grid.template.strokeWidth = 0;
  xAxix.startLocation = 0.5;
  xAxix.endLocation = 0.5;
  xAxix.renderer.line.strokeOpacity = 0;
  xAxix.renderer.line.strokeWidth = 0;
  xAxix.renderer.labels.template.fill = am4core.color('#494949');

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.grid.template.strokeWidth = 0;
  valueAxis.renderer.labels.template.fill = am4core.color('#178255');
  valueAxis.renderer.labels.template.disabled = false;

  chart.fontSize = 12;

  const seriesSmartWealth = chart.series.push(new am4charts.LineSeries());
  seriesSmartWealth.dataFields.valueY = 'ay';
  seriesSmartWealth.dataFields.dateX = 'date';
  seriesSmartWealth.tooltipText = '${ay}';
  seriesSmartWealth.strokeWidth = 0;
  seriesSmartWealth.minBulletDistance = 15;
  seriesSmartWealth.tooltip.getFillFromObject = true;
  seriesSmartWealth.tooltip.autoTextColor = false;
  seriesSmartWealth.fill = '#e5fde9';

  seriesSmartWealth.fillOpacity = 1;
  seriesSmartWealth.tooltip.background.fill = am4core.color('#262935');
  seriesSmartWealth.tooltip.background.cornerRadius = 4;
  seriesSmartWealth.tooltip.background.strokeWidth = 0;
  seriesSmartWealth.tooltip.getFillFromObject = false;
  seriesSmartWealth.tooltip.getStrokeFromObject = false;
  seriesSmartWealth.tooltip.width = 300;
  seriesSmartWealth.tooltip.fontSize = 16;

  const bullet = seriesSmartWealth.bullets.push(new am4charts.CircleBullet());
  const bullethover = bullet.states.create('hover');
  bullet.properties.scale = 0;
  bullethover.properties.scale = 1.3;

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.lineY.disabled = true;
  chart.cursor.lineY.fillColor = '#fff';
}

const pressSliderConfig = {
  type: 'carousel',
  gap: '15px',
  direction: window.location.pathname.indexOf('/ar/') !== -1 ? 'rtl' : 'ltr',
  breakpoints: {
    991: {
      perView: 2,
    },

    768: {
      perView: 1,
    },
  },
};

if (window.location.pathname.indexOf('/about-us') !== -1) {
  const overviewGlide = new Glide('.glide--press', pressSliderConfig);
  overviewGlide.mount();
}

if (document.body.id === 'landing') {
  const initialInvestmentSlider = document.getElementById(
    'initialInvestmentSlider'
  );
  const initialInvestmentValue = document.getElementById(
    'initialInvestmentValue'
  );
  const monthlyDepositSlider = document.getElementById('monthlyDepositSlider');
  const monthlyDepositValue = document.getElementById('monthlyDepositValue');
  const nbkLegend = document.getElementById('sw_legend');
  const savingLegend = document.getElementById('saving_legend');
  const monthlyDeposit = monthlyDepositSlider.value;
  const initialInvestment = initialInvestmentSlider.value;
  const monthlyProgressBar = document.querySelector(
    '.slider__progress--monthly'
  );
  const initialProgressBar = document.querySelector(
    '.slider__progress--initial'
  );

  initialInvestmentSlider.addEventListener('change', () => {
    calculateChartData();
  });
  monthlyDepositSlider.addEventListener('change', () => {
    calculateChartData();
  });
  initialInvestmentValue.innerHTML = `$${formatNumber(initialInvestment)}`;
  monthlyDepositValue.innerHTML = `$${formatNumber(monthlyDeposit)}`;

  initialInvestmentSlider.oninput = function () {
    initialInvestmentValue.innerHTML = `$${formatNumber(this.value)}`;
    initialProgressBar.style.width = `${this.value / 10000}%`;
  };

  monthlyDepositSlider.oninput = function () {
    monthlyDepositValue.innerHTML = `$${formatNumber(this.value)}`;
    monthlyProgressBar.style.width = `${this.value / 100}%`;
  };

  const calculateChartData = () => {
    const swData = [];
    const savingData = [];
    const monthlyDeposit = monthlyDepositSlider.value;
    const initialInvestment = initialInvestmentSlider.value;
    const totalYears = 30;
    const frequency = 12;
    const swRate = 0.048;
    const savingRate = 0.005;
    for (let nbOfYears = 1; nbOfYears <= totalYears; nbOfYears++) {
      const entry =
        initialInvestment *
          Math.pow(1 + swRate / frequency, nbOfYears * frequency) +
        monthlyDeposit *
          ((Math.pow(1 + swRate / frequency, nbOfYears * frequency) - 1) /
            (swRate / frequency));
      swData.push(entry.toFixed(0));
    }
    nbkLegend.innerHTML = `$${
      (swData[29] && formatNumber(swData[29])) || formatNumber(50000)
    }`;

    for (let nbOfYears = 1; nbOfYears <= totalYears; nbOfYears++) {
      const entry =
        initialInvestment *
          Math.pow(1 + savingRate / frequency, nbOfYears * frequency) +
        monthlyDeposit *
          ((Math.pow(1 + savingRate / frequency, nbOfYears * frequency) - 1) /
            (savingRate / frequency));
      savingData.push(entry.toFixed(0));
    }
    savingLegend.innerHTML = `$${
      (savingData[29] && formatNumber(savingData[29])) || formatNumber(30000)
    }`;
    performanceChart(sortData(swData, savingData));
  };

  const sortData = (swData, savingData) => {
    const data = [];
    const totalYears = 30;
    for (let index = 1; index <= totalYears; index++) {
      data.push({
        date: moment().add(index - 1, 'years'),
        ay: formatNumber(swData[index - 1]),
        by: formatNumber(savingData[index - 1]),
      });
    }
    return data;
  };

  const getTooltip = () =>
    `<center style="font-weight: 500; font-size: 14px">{ay}</center>`;

  const performanceChart = (data) => {
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;
    const chart = am4core.create('performance-chart', am4charts.XYChart);
    chart.data = data || [];
    chart.logo.disabled = true;
    chart.colors.list = [am4core.color('#1439da'), am4core.color('#d0dcfc')];
    am4core.useTheme(am4themes_animated);
    chart.dateFormatter.inputDateFormat = 'yyyy';
    chart.rtl = window.location.href.indexOf('/ar/') !== -1;
    const xAxix = chart.xAxes.push(new am4charts.DateAxis());
    // xAxix.tooltipDateFormat = "yyyy";
    xAxix.tooltip.disabled = true;
    xAxix.renderer.grid.template.strokeWidth = 0;
    xAxix.startLocation = 0.5;
    xAxix.endLocation = 0.5;
    xAxix.renderer.line.strokeOpacity = 0;
    xAxix.renderer.line.strokeWidth = 0;
    xAxix.renderer.labels.template.fill = am4core.color('#494949');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.opposite = true;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.renderer.labels.template.fill = am4core.color('#494949');
    valueAxis.renderer.labels.template.disabled = true;

    chart.fontSize = 12;

    const seriesSmartWealth = chart.series.push(new am4charts.LineSeries());
    seriesSmartWealth.dataFields.valueY = 'ay';
    seriesSmartWealth.dataFields.dateX = 'date';
    seriesSmartWealth.tooltipText = '${ay}';
    seriesSmartWealth.strokeWidth = 0;
    seriesSmartWealth.minBulletDistance = 15;
    seriesSmartWealth.tooltip.getFillFromObject = true;
    seriesSmartWealth.tooltip.getFillFromObject = true;
    seriesSmartWealth.tooltip.autoTextColor = false;
    seriesSmartWealth.fillOpacity = 1;
    seriesSmartWealth.fill = '#1439da';
    seriesSmartWealth.tooltip.background.fill = am4core.color('#262935');
    seriesSmartWealth.tooltip.background.cornerRadius = 4;
    seriesSmartWealth.tooltip.background.strokeWidth = 0;
    seriesSmartWealth.tooltip.getFillFromObject = false;
    seriesSmartWealth.tooltip.getStrokeFromObject = false;
    seriesSmartWealth.tooltip.width = 300;
    seriesSmartWealth.tooltip.fontSize = 16;

    const savingBenchmark = chart.series.push(new am4charts.LineSeries());
    savingBenchmark.dataFields.valueY = 'by';
    savingBenchmark.dataFields.dateX = 'date';
    savingBenchmark.tooltipText = '${by}';
    savingBenchmark.strokeWidth = 0;
    savingBenchmark.minBulletDistance = 15;
    savingBenchmark.tooltip.getFillFromObject = true;
    savingBenchmark.tooltip.autoTextColor = false;
    savingBenchmark.fill = '#d0dcfc';
    savingBenchmark.fillOpacity = 1;
    savingBenchmark.tooltip.background.fill = am4core.color('#262935');
    savingBenchmark.tooltip.background.cornerRadius = 4;
    savingBenchmark.tooltip.background.strokeWidth = 0;
    savingBenchmark.tooltip.getFillFromObject = false;
    savingBenchmark.tooltip.getStrokeFromObject = false;
    savingBenchmark.tooltip.width = 300;
    savingBenchmark.tooltip.fontSize = 16;

    const bullet = seriesSmartWealth.bullets.push(new am4charts.CircleBullet());
    const bullethover = bullet.states.create('hover');
    bullet.properties.scale = 0;
    bullethover.properties.scale = 1.3;

    const bulletSaving = savingBenchmark.bullets.push(
      new am4charts.CircleBullet()
    );
    const bulletSavinghover = bulletSaving.states.create('hover');
    bulletSaving.properties.scale = 0;
    bulletSavinghover.properties.scale = 1.3;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineY.fillColor = '#fff';

    // if (isIE) {
    //   seriesSmartWealth.tooltipText = `{dateTooltip}
    //        ---------
    //        Amount {value}`;
    // } else {
    //   seriesSmartWealth.tooltipHTML = getTooltip();
    // }
  };

  calculateChartData();
}

let sectionHeaders = document.querySelectorAll('.section__header');
sectionHeaders = [].slice.call(sectionHeaders);

sectionHeaders.forEach((sectionHeader) =>
  sectionHeader.addEventListener('click', () => {
    let isContains = false;
    [...sectionHeader.classList].forEach(item => {
      if(item === 'section__header--active') {
        isContains = true;
      }
    });
    if (isContains) {
      sectionHeader.classList.remove('section__header--active');
      sectionHeader.children[1].classList.remove('section__icon--active');
      sectionHeader.nextElementSibling.classList.remove(
        'section__content--active'
      );

      return;
    }
    sectionHeader.classList.add('section__header--active');
    sectionHeader.children[1].classList.add('section__icon--active');
    sectionHeader.nextElementSibling.classList.add('section__content--active');
  })
);

function getQueryParam(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

if (document.body.id === "unblock") {
  const status = getQueryParam("status");
  const iconElement = document.getElementById("icon");
  const titleElement = document.getElementById("title");
  const descriptionElement = document.getElementById("description");
  const iconImg = document.createElement("img");
  const locale = window.location.pathname.indexOf("/ar/") !== -1 ? "ar" : "en";
  iconImg.style.width = "84px";

  const unblockEn = {
    success: {
      icon: "/assets/images/tick.svg",
      title: "Account unblocked",
      description: "Your account has been unblocked",
    },
    failed: {
      icon: "/assets/images/error.svg",
      title: "The attempt to unblock your account was unsuccessful",
      description: "Please try again later",
    },
    error: {
      icon: "/assets/images/error.svg",
      title: "An error has occurred",
      description: "Please try again later",
    },
  };

  const unblockAr = {
    success: {
      icon: "/assets/images/tick.svg",
      title: "الحساب مفتوح",
      description: "تم فتح حسابك",
    },
    failed: {
      icon: "/assets/images/error.svg",
      title: "محاولة فتح حسابك لم تكن ناجحة",
      description: "الرجاء إعادة المحاولة لاحقاً",
    },
    error: {
      icon: "/assets/images/error.svg",
      title: "حدث خطأ ما",
      description: "الرجاء إعادة المحاولة لاحقاً",
    },
  };

  const unblockLocale = locale === "en" ? unblockEn : unblockAr;

  if (status) {
    iconImg.src = unblockLocale[status].icon;
    titleElement.innerHTML = unblockLocale[status].title;
    descriptionElement.innerHTML = unblockLocale[status].description;
  } else {
    iconImg.src = unblockLocale["success"].icon;
    titleElement.innerHTML = unblockLocale["success"].title;
    descriptionElement.innerHTML = unblockLocale["success"].description;
  }

  iconElement.appendChild(iconImg);
}
